import { KeyboardEvent } from 'react';
import { InputValidations } from '@core/services/ValidateService';


export enum InputTypes {
    BUTTON = 'button',
    CHECKBOX = 'checkbox',
    COLOR = 'color',
    DATE = 'date',
    DATETIME_LOCAL = 'datetime-local',
    EMAIL = 'email',
    FILE = 'file',
    HIDDEN = 'hidden',
    IMAGE = 'image',
    MONTH = 'month',
    NUMBER = 'number',
    PASSWORD = 'password',
    RADIO = 'radio',
    RANGE = 'range',
    RESET = 'reset',
    SEARCH = 'search',
    SUBMIT = 'submit',
    TEL = 'tel',
    TEXT = 'text',
    TIME = 'time',
    URL = 'url',
    WEEK = 'week',
}

export enum InputInsertEvents {
    INSERT_TEXT = 'insertText',
    INSERT_FROM_PASTE = 'insertFromPaste',
    INSERT_FROM_DROP = 'insertFromDrop',
    INSERT_NON_TEXT = 'insertNonText',
    INSERT_FROM_YANK = 'insertFromYank',
}


export interface ITextInputProps {
    typeInput?: string | null;
    value: string;
    length?: number | null;
    onChangeValue: (value: string) => void;
    onChangeValid?: (val: boolean) => void;
    onKeyPress?: (event: KeyboardEvent) => void;
    onClick?: () => void;
    name?: string;
    placeholder?: string;
    className?: string;
    label?: string;
    error?: string;
    type?: InputTypes;
    id?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
    validType?: InputValidations;
    min?: number;
    max?: number;
    isValid?: boolean;
    readonly?: boolean;
    inputMask?: boolean;
    inputMaskRegExp?: string;
    inputMaskChar?: string;
    maxlength?: number;
    fixedPart?: string;
    dataCyValue?: string;
    options?: string[];
    solidPhone?: boolean;
}
