import {
    types as t,
    Instance,
    SnapshotIn,
    SnapshotOut,
    getEnv, applySnapshot,
} from 'mobx-state-tree';
import { v4 } from 'uuid';

import map from 'lodash/map';

import { AttrsKey } from '@interfaces/form.interface';
import { UseMaps } from '@components/main/order-page/order-address/models';
import { customI18NTFunction } from '@services/I18NService';
import { FormAttributes, IFormAttributesModel } from '@models/mobx-state-tree/formAttributes.model';
import { OrderStatus } from '@models/mobx-state-tree/orderStatus.model';
import { ChangeStatusButton, IChangeStatusButtonModel } from '@models/mobx-state-tree/changeStatusButtons.model';
import { ConditionForFreeShipping } from '@models/mobx-state-tree/conditionForFreeShipping.model';
import { IEnv } from '@store/store';
import { ICustomerPhoneModel } from '@models/mobx-state-tree/customerPhone.model';
import { MAX_ITEMS_COUNT } from '@core/constants/limits';
import {
    KladrResultsForAllForms, KladrResultsForAllFormsModelSnapshotIn,
} from '@models/mobx-state-tree/KladrResultsForAllForms.model';
import { IKladrSearchResult } from '@api/kladr-api-service/models';
import { IKladrResultModelSnapshotIn } from '@models/mobx-state-tree/kladrResult.model';


const DEFAULT_ADDRESS_ATTRIBUTES = {
    [AttrsKey.CUSTOMER_DISTRICT]: {
        id: AttrsKey.CUSTOMER_DISTRICT,
    },
    [AttrsKey.CUSTOMER_ZIP]: {
        id: AttrsKey.CUSTOMER_ZIP,
    },
};

export const FastChangeQueue = t
    .model('FastChangeQueue', {
        id: t.number,
        name: t.string,
    });

export const FormModel = t
    .model('FormModel', {
        id: t.identifier,
        useMap: t.maybeNull(t.boolean),
        useAzureMap: t.maybeNull(t.boolean),
        useGeonegociosMap: t.maybeNull(t.boolean),
        useForeignDeliveryBroker: t.maybeNull(t.boolean),
        changeStatusButtons: t.optional(t.array(ChangeStatusButton), []),
        addressAttributes: t.optional(t.map(FormAttributes), DEFAULT_ADDRESS_ATTRIBUTES),
        generalAttributes: t.optional(t.map(FormAttributes), {}),
        additionalPhones: t.optional(t.array(t.string), []),
        conditionForFreeShipping: t.maybeNull(ConditionForFreeShipping),
        activatePromoFrom: t.maybeNull(t.number),
        isCardPaymentAvailable: t.maybeNull(t.boolean),
        maxRecallDate: t.maybeNull(t.number),
        maskPhones: t.optional(t.boolean, false),
        checkAddress: t.optional(t.boolean, false),
        checkZip: t.optional(t.boolean, false),
        statuses: t.optional(t.map(OrderStatus), {}),
        fastChangeQueues: t.optional(t.array(FastChangeQueue), []),
        allowGifts: t.optional(t.boolean, false),
        wholesale: t.optional(t.boolean, false), // предусмотрена ли возможность торговать оптом, которую еще надо включить в wholesaleEnabled
        wholesaleLimit: t.optional(t.number, MAX_ITEMS_COUNT), // после какого числа товаров считается оптом
        showWholesaleConfirm: t.optional(t.boolean, false), // показывать ли попап в котором мы спросим можно ли продавать оптом
        wholesaleEnabled: t.optional(t.boolean, false),
        showWholesaleConfirmBasketId: t.maybeNull(t.string),
        showWholesaleConfirmQuantity: t.maybeNull(t.number),
        notUseCallAutomatization: t.optional(t.boolean, false),
        considerRemains: t.optional(t.boolean, false),
        /**
         * Запрет аппрува заказа при отсутствующей К.С.
         * по умолчанию true, т.е. разрешено без К.С.
         */
        approveWithoutShipping: t.optional(t.boolean, true),
        /**
         * Количество секунд после начала разговора, после которых кнопка recall должна стать доступной
         */
        enableRecallAfterCallSeconds: t.maybeNull(t.integer),
        extraPrice: t.maybeNull(t.integer),
        maxGiftsCount: t.maybeNull(t.integer),
        useAddressClassifier: t.maybeNull(t.boolean),
        setZipByDistrict: t.maybeNull(t.boolean),
        disableStatusSubStatusPhoneButtons: t.optional(t.boolean, false),
        structure: t.map(t.frozen()),
        kladrResultsForAllForms: t.optional(t.array(KladrResultsForAllForms), []),
        changeSelect: t.optional(t.boolean, false),
        clearKladr: t.optional(t.boolean, false),
    })
    .views((self) => ({
        get t(): customI18NTFunction {
            const { I18NService: { t } } = getEnv<IEnv>(self);

            return t;
        },

        get customerPhone(): ICustomerPhoneModel | null {
            const customerPhoneModel = self.generalAttributes.get(AttrsKey.CUSTOMER_PHONE);

            if (customerPhoneModel?.customerPhone) {
                return customerPhoneModel.customerPhone;
            }

            return null;
        },

        get customerZipAttribute(): IFormAttributesModel | undefined {
            return self.addressAttributes.get(AttrsKey.CUSTOMER_ZIP);
        },

        get customerDistrictAttribute(): IFormAttributesModel | undefined {
            return self.addressAttributes.get(AttrsKey.CUSTOMER_DISTRICT);
        },

        get generalAttributeValues(): IFormAttributesModel[] {
            return [...self.generalAttributes.values()];
        },

        get addressAttributeValues(): IFormAttributesModel[] {
            return [...self.addressAttributes.values()];
        },

        get changeStatusButtonsValues(): IChangeStatusButtonModel[] {
            return [...self.changeStatusButtons.values()];
        },

        get additionalPhonesValues(): string[] {
            return [...self.additionalPhones.values()];
        },

        get fastChangeQueuesValues(): IFastChangeQueueModel[] {
            return [...self.fastChangeQueues.values()];
        },

        get currentUseMap(): UseMaps | null {
            return self.useMap ? UseMaps.GOOGLE_MAP : (
                self.useAzureMap ? UseMaps.AZURE_MAP : (
                    self.useGeonegociosMap ? UseMaps.GEONEGOCIOS_MAP : null
                )
            );
        },

        get kladrResultsForAllFormsValues(): KladrResultsForAllFormsModelSnapshotIn[] {
            return [...self.kladrResultsForAllForms.values()];
        },
    }))
    .views(((self) => ({
        get addedPhonesByOperator(): IFormAttributesModel[] {
            return self.generalAttributeValues
                .filter((x: IFormAttributesModel) => x.additionalPhone);
        },

        get generalAttrsForForm(): IFormAttributesModel[] {
            return self.generalAttributeValues
                .filter((x: IFormAttributesModel) => !x.additionalPhone);
        },

        get addressAttributesForForm(): IFormAttributesModel[] {
            return self.addressAttributeValues
                .filter((x: IFormAttributesModel) => x.name !== AttrsKey.CUSTOMER_ZIP);
        },

        get addressAttributesForFormToSave(): IFormAttributesModel[] {
            return self.addressAttributeValues;
        },
        get filledAddressAttributes(): Record<string, string>[] {
            return self.addressAttributeValues.reduce((acc: Record<string, string>[],
                el: IFormAttributesModel) => {
                return el.value?.length ? [...acc, { [el.id]: el.value }] : acc;
            }, []);
        },
        get selectedAddressAttributes(): KladrResultsForAllFormsModelSnapshotIn[] | IKladrSearchResult[] {
            return self.kladrResultsForAllFormsValues.filter(el => {
                return [...this.filledAddressAttributes].every(elem => {
                    const key = Object.keys(elem)[0];
                    return el[key as keyof typeof el] === elem[key];
                });
            });
        },
    })))
    .actions((self) => ({
        deleteAddedPhone(id: string): void {
            if (self.addedPhonesByOperator.length === 1) return;
            self.generalAttributes.delete(id);
        },

        addGeneralAttributes(value: string): void {

            const formAttributes = FormAttributes.create({
                id: `${AttrsKey.ADDITIONAL_PHONE}__${v4()}`,
                required: false,
                type: 'text',
                regex: null,
                length: 20,
                isKlader: false,
                isKladrOnly: false,
                isCoordinate: null,
                isDisabledUserData: null,
                name: 'additional_phone',
                label: 'Дополнительный телефон',
                _value: value,
            });

            self.generalAttributes.put(formAttributes);
        },
        setChangeSelect() {
            self.changeSelect = !self.changeSelect;
        },
        setClearKladr(value: boolean) {
            self.clearKladr = value;
        },
        getDataForSubstituteId(prepareDataForCladAutoFill: (results: IKladrSearchResult[], formAttributeName: string) => IKladrResultModelSnapshotIn[], id: string): any {
            return prepareDataForCladAutoFill(self.selectedAddressAttributes as IKladrSearchResult[], id);
        },
    }))
    .actions((self) => ({
        needShowWholesaleConfirm(basketId: string, quantity: number): void {
            self.showWholesaleConfirm = true;
            self.showWholesaleConfirmBasketId = basketId;
            self.showWholesaleConfirmQuantity = quantity;
        },
        hideWholesaleConfirm(): void {
            self.showWholesaleConfirm = false;
            self.showWholesaleConfirmBasketId = null;
            self.showWholesaleConfirmQuantity = null;
        },
        setWholesaleEnabled(value: boolean): void {
            self.wholesaleEnabled = value;
        },
        renderAdditinalPhones(): void {
            const additionalPhones = self.additionalPhonesValues.length ? self.additionalPhonesValues : [''];
            map(additionalPhones, self.addGeneralAttributes);
        },
        addPhone(): void {
            if (self.addedPhonesByOperator.length === 3) {
                return;
            }
            map([''], self.addGeneralAttributes);
        },
        setDisableStatusSubStatusPhoneButtons(disable: boolean): void {
            self.disableStatusSubStatusPhoneButtons = disable;
        },
        getParentName(id: string): string | undefined {
            return self.structure.get(id) as string;
        },
        getParentData(id: string): string | undefined {
            const parentFieldName =  self.structure.get(id);

            const parentArray = self.addressAttributes.get(parentFieldName);
            return parentArray?.value;
        },
    }))
    .actions((self) => ({
        createKladrResultsForAllForms(data: KladrResultsForAllFormsModelSnapshotIn[]): void {
            applySnapshot(self.kladrResultsForAllForms, data);
        },
    }));


export interface IFormModel extends Instance<typeof FormModel> {}
export interface IFormModelSnapshotIn extends SnapshotIn<typeof FormModel> {}
export interface IFormModelSnapshotOut extends SnapshotOut<typeof FormModel> {}

export interface IFastChangeQueueModel extends Instance<typeof FastChangeQueue> {}
export interface IFastChangeQueueModelSnapshotIn extends SnapshotIn<typeof FastChangeQueue> {}
export interface IFastChangeQueueModelSnapshotOut extends SnapshotOut<typeof FastChangeQueue> {}
