import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react';

import I18NService from '@services/I18NService';
import { ISimilarOrderModel } from '@models/mobx-state-tree/similarOrder.model';
import { IBasketItemModel } from '@/app/models/mobx-state-tree/newModels/BasketItem.model';
import EmptyMainProductsNotification from '../includes/EmptyMainProductsNotification';


interface ISimilarOrderTableProps {
    similarOrders: ISimilarOrderModel[];
    isShow: boolean;
    readonly mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved: IBasketItemModel[];
    readonly animationOnOrErrorFieldsActive: boolean;
    readonly isPromotionBuilderActive: boolean;
}

const SimilarOrderTable: FunctionComponent<ISimilarOrderTableProps> = ({
    similarOrders,
    isShow = false,
    mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved = [],
    animationOnOrErrorFieldsActive = false,
    isPromotionBuilderActive,
}) => {
    const { t } = useInstance(I18NService);

    return (
        <div className={cn('currentProducts__table', !isShow && 'hiddenBlock')}>
            <div className="currentProducts__table-head similarOrders-head">
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    №
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('Дата', 'Date')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('Партнер', 'Partner')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('# у партнера', '# at partner')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-1">
                    {t('Статус', 'Status')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-2">
                    {t('Имя клиента', 'Customer name')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-2">
                    {t('Продукты', 'Products')}
                </div>
                <div className="currentProducts__table-col currentProducts__table-col-2">
                    {t('Телефон', 'Telephone')}
                </div>
            </div>
            {
                !mainItemsInBasketWithQuantityMoreThanZeroAndNotRemoved.length
                && !isPromotionBuilderActive
                && (
                    <div>
                        <div className="currentProducts__table-container similarOrders-body">
                            <div className="currentProducts__table-container-rows">
                                <EmptyMainProductsNotification
                                    className="currentProducts__table-row EmptyValueForMainProducts"
                                    animationOnOrErrorFieldsActive={animationOnOrErrorFieldsActive}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !similarOrders.length && (
                    <strong className="currentProducts__table-noProducts">
                        {t('Нет похожих заказов', 'No similar orders')}
                    </strong>
                )
            }
            {
                similarOrders.length > 0 && similarOrders.map((similarOrder: ISimilarOrderModel) => (
                    <div key={String(similarOrder.id)}>
                        <div className="currentProducts__table-container similarOrders-body">
                            <div className="currentProducts__table-container-rows">
                                <div className="currentProducts__table-row">
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.id}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col--date"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.formattedCreatedDate}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.partner}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.foreignId}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-1"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.status && t(similarOrder.status)}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-2"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.customerFullName}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-2"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.products}
                                        </div>
                                    </div>
                                    <div
                                        className="currentProducts__table-col currentProducts__table-col-2"
                                    >
                                        <div className="currentProducts__table-text">
                                            {similarOrder.maskPhone && similarOrder.encryptedCustomerPhone}
                                            {!similarOrder.maskPhone && similarOrder.customerPhone}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};


export default observer(SimilarOrderTable);
