import {
    Instance, types as t, SnapshotOut, SnapshotIn,
} from 'mobx-state-tree';


export const LENGTH_OF_MASKED_PHONE_END = 4;


export const CustomerPhone = t
    .model('CustomerPhone', {
        id: t.identifier,
        originalValue: t.optional(t.string, ''), // исходное значение (не меняется)
        _variablePhonePart: t.optional(t.string, ''), // изменяемая часть телефона
        maskPhone: t.optional(t.boolean, false),
        phoneEditingRangeInfo: t.optional(t.integer, 0),
        solidPhone: t.optional(t.boolean, true),  // в зависисмости от параметра телефонный номер пользователя отображается в виде двух блоков или в одном блоке.
    })
    .views((self) => ({
        get originalPhoneNumberLength(): number {
            return self.originalValue.length;
        },
    }))
    .views((self) => ({
        /**
         * Фактически изменяемая часть телефона - вызываем 1 раз после создания модели customerPhone
         */
        get variablePhonePart(): string {
            if (self.maskPhone) {
                if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что можно номер нельзя редактировать, а в изменяемой части пусто (можем вбить туда 3 символа)
                    return '';
                }

                // если интервал редактирования телефона >= длине номера телефона МИНУС 4 символа (длина маскируемой части), то возвращаем всё кроме маски
                if (self.phoneEditingRangeInfo >= self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    return self.originalValue.slice(0, self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END);
                }

                // если интервал редактирования < длины номера телефона МИНУС 4 символа (длина маски)
                if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    // берем кусок номера, который между маской и редактируемой частью
                    return self.originalValue.slice(0, self.phoneEditingRangeInfo);
                }
            }

            if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что номер редактировать нельзя
                return '';
            }

            if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength) {
                return self.originalValue.slice(0, self.phoneEditingRangeInfo);
            }

            // если phoneEditingRangeInfo больше или равен длине номера телефона => можно редактировать весь номер целиком
            return self.originalValue;
        },
        /**
         * Неизменяемая часть телефона
         */
        get invariablePhonePart(): string {
            if (self.maskPhone) {
                const lastFourDigitsMasked = 'XXXX';

                if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что номер редактировать нельзя
                    const unmaskedPart = self.originalValue.slice(0, self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END);

                    return unmaskedPart.concat(lastFourDigitsMasked);
                }

                // если интервал редактирования телефона >= длине номера телефона МИНУС 4 символа (длина маскируемой части), то у нас останется только маска
                if (self.phoneEditingRangeInfo >= self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    return lastFourDigitsMasked;
                }

                // если интервал редактирования < длины номера телефона МИНУС 4 символа
                if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END) {
                    // берем кусок номера, который между маской и редактируемой частью
                    const unmaskedPart = self.originalValue.slice(self.phoneEditingRangeInfo, self.originalPhoneNumberLength - LENGTH_OF_MASKED_PHONE_END);

                    return unmaskedPart.concat(lastFourDigitsMasked);
                }
            }

            if (self.phoneEditingRangeInfo === 0) { // равносильно тому, что номер редактировать нельзя
                return self.originalValue;
            }

            if (self.phoneEditingRangeInfo < self.originalPhoneNumberLength) {
                // берем кусок номера, который остается после редактируемой части
                const unmaskedPart = self.originalValue.slice(self.phoneEditingRangeInfo, self.originalPhoneNumberLength);

                return unmaskedPart;
            }

            // если phoneEditingRangeInfo больше или равен длине номера телефона => можно редактировать весь номер целиком
            return '';
        },
    }))
    .views((self) => ({
        /**
         * Длина по факту изменяемой части телефона + 3 символа
         */
        get phoneEditingRange(): number {
            return self.variablePhonePart.length + 3;
        },
        /**
         * Используется для отображения в UI интерфейсе. \
         * Если maskPhone = true, то номер будет замаскирован
         */
        get phoneForUI(): string {
            return self._variablePhonePart.concat(self.invariablePhonePart);
        },
        /**
         * Используется в коде. Итоговый, возможно отредактированный номер телефона
         */
        get phoneForCode(): string {
            if (!self.maskPhone) {
                return self._variablePhonePart.concat(self.invariablePhonePart);
            }
            // отрезаем маску и вместо нее цепляем последние 4 символа из исходного номера
            const unMaskedValue = self.invariablePhonePart
                .slice(0, -LENGTH_OF_MASKED_PHONE_END)
                .concat(self.originalValue
                    .slice(self.originalValue.length - LENGTH_OF_MASKED_PHONE_END));

            return self._variablePhonePart.concat(unMaskedValue);
        },
    }))
    .actions((self) => ({
        setVariablePhonePart(value: string): void {
            self._variablePhonePart = value;
        },
    }))
    .actions(((self) => ({
        afterCreate() {
            self.setVariablePhonePart(self.variablePhonePart);
        },
        setValue(value: string): void {
            if (value === '') { // можно полностью удалить изменяющуюся часть номера телефона
                self.setVariablePhonePart('');
                return;
            }
            const isNum = /^\d+$/.test(value);

            if (isNum) {
                self.setVariablePhonePart(value);
            }
        },
    })));


export interface ICustomerPhoneModel extends Instance<typeof CustomerPhone> {}
export interface ICustomerPhoneSnapshotIn extends SnapshotIn<typeof CustomerPhone> {}
export interface ICustomerPhoneSnapshotOut extends SnapshotOut<typeof CustomerPhone> {}
