import {
    types as t, Instance, SnapshotIn, SnapshotOut,
} from 'mobx-state-tree';
import moment from 'moment';

import encryptedPhone from '@core/helpers/encryptedPhone';

export const SimilarOrder = t
    .model('SimilarOrder', {
        id: t.maybeNull(t.number),
        partner: t.maybeNull(t.string),
        foreignId: t.maybeNull(t.number),
        status: t.maybeNull(t.string),
        customerFullName: t.maybeNull(t.string),
        products: t.maybeNull(t.string),
        customerPhone: t.maybeNull(t.string),
        maskPhone: t.optional(t.boolean, false),
        createdDate: t.maybeNull(t.number),
    })
    .views((self) => ({
        get encryptedCustomerPhone(): string {
            if (!self.customerPhone) {
                return '';
            }

            return encryptedPhone(self.customerPhone);
        },
        get formattedCreatedDate(): string {
            if (!self.createdDate) {
                return '';
            }

            const createdDate = moment(self.createdDate * 1000);
            if (createdDate.isValid()) {
                return createdDate.format('DD.MM.YYYY');
            }

            return '';
        },
    }));


export interface ISimilarOrderModel extends Instance<typeof SimilarOrder> {}
export interface ISimilarOrderModelSnapshotIn extends SnapshotIn<typeof SimilarOrder> {}
export interface ISimilarOrderModelSnapshotOut extends SnapshotOut<typeof SimilarOrder> {}
