import {
    getEnv, Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

import ValidateService, { InputValidations } from '@core/services/ValidateService';
import { AttrsKey } from '@interfaces/form.interface';
import { InputTypes } from '@UIElements/TextInput/models';

import { IKladrResultModel, KladrResult } from '@models/mobx-state-tree/kladrResult.model';
import { IEnv } from '@store/store';
import { CustomerPhone } from '@models/mobx-state-tree/customerPhone.model';
import { customI18NTFunction } from '@services/I18NService';
import { OptionFormAttribute } from '@models/mobx-state-tree/optionFormAttributes.model';
import { OrderStatusesEnum } from '@api/order-api-service/models';

export const FormAttributes = t
    .model('FormAttributes', {
        id: t.identifier,
        formAttributeId: t.maybeNull(t.integer),
        name: t.maybe(t.string),
        label: t.maybeNull(t.string),
        required: t.maybeNull(t.boolean),
        type: t.maybeNull(t.string),
        regex: t.maybeNull(t.string),
        length: t.maybeNull(t.integer),
        isKlader: t.maybeNull(t.boolean),
        isKladrOnly: t.maybeNull(t.boolean),
        isCoordinate: t.maybeNull(t.boolean),
        isDisabledUserData: t.maybeNull(t.boolean),
        kladrResult: t.optional(KladrResult, { id: '0', name: '' }),
        customerPhone: t.maybeNull(CustomerPhone),
        _value: t.maybeNull(t.string),
        data: t.array(t.string),
        values: t.optional(t.array(t.string), []),
        keys: t.optional(t.array(t.string), []),
        _option: t.optional(OptionFormAttribute, { label: '', value: '' }),
        _key: t.optional(t.string, ''),
        minLength: t.maybeNull(t.integer),
        isExceedingMaxLength: t.optional(t.boolean, false),
    })
    .views((self) => ({
        get _env(): IEnv {
            return getEnv<IEnv>(self);
        },

        get additionalPhone(): boolean {
            return self.id.includes(AttrsKey.ADDITIONAL_PHONE);
        },
    }))
    .views((self) => ({
        get validateService(): ValidateService {
            return self._env.validateService;
        },

        get t(): customI18NTFunction {
            return self._env.I18NService.t;
        },

        get value(): string {
            if (self.name === AttrsKey.CUSTOMER_PHONE) {
                if (!self.customerPhone || !self.customerPhone.phoneForCode) {
                    return '';
                }

                return self.customerPhone.phoneForCode;
            }

            return self._value && self._value || '';
        },

        get key(): string {
            return self._key;
        },

        get fieldValue(): string {
            if (self.isKlader && self.isKladrOnly) {
                return self.kladrResult.name?.length
                    ? self.kladrResult.name
                    : '';
            }

            if (self.name === AttrsKey.CUSTOMER_PHONE && self.customerPhone) {
                return self.customerPhone.phoneForUI;
            }

            if (self._value?.length) {
                return self._value;
            }

            return '';
        },

        get selected(): { label: string; value: string } {
            return self._option;
        },

        get fieldType(): InputTypes {
            const { id, additionalPhone } = self;

            if (
                id === AttrsKey.CUSTOMER_MOBILE
                || id === AttrsKey.CUSTOMER_PHONE
                || additionalPhone
            ) {
                return InputTypes.TEL;
            }

            return InputTypes.TEXT;
        },

        get typeOfValidation(): InputValidations | null {
            const { id, additionalPhone } = self;

            if (
                id === AttrsKey.CUSTOMER_PHONE
                || id === AttrsKey.CUSTOMER_MOBILE
                || additionalPhone
            ) {
                return InputValidations.PHONE;
            }

            if (id === AttrsKey.CUSTOMER_EMAIL) {
                return InputValidations.EMAIL;
            }

            if (id === AttrsKey.CUSTOMER_FULL_NAME) {
                return InputValidations.FULL_NAME;
            }

            if (id === AttrsKey.CUSTOMER_AGE) {
                return InputValidations.AGE;
            }

            return null;
        },

        get disabled(): boolean {
            return false;
        },
    }))
    .views((self) => ({
        get valueLength(): number {
            return self.fieldValue.length || 0;
        },
    }))
    .views((self) => ({
        get isValueLengthValid(): boolean {
            const isLength = self.valueLength > 0;

            if (self.length) {
                return isLength && self.valueLength <= self.length;
            }

            return isLength;
        },
    }))
    .views((self) => ({
        get isValid(): boolean {
            let validResult = true;

            if (self.isExceedingMaxLength) {
                return false;
            }

            if (!self.required && (self.fieldValue === '' && self.selected.value === '')) {
                return true;
            }

            switch (self.typeOfValidation) {
                case InputValidations.EMAIL: {
                    validResult = self.validateService.isEmailValid(self.fieldValue);
                    break;
                }

                case InputValidations.LATIN: {
                    validResult = self.validateService.isLatinValid(self.fieldValue);
                    break;
                }

                case InputValidations.PHONE: {
                    if (self.name === AttrsKey.CUSTOMER_PHONE) {
                        validResult = self.validateService.isFiguresValid(self.value);
                        break;
                    }

                    validResult = self.validateService.isFiguresValid(self.fieldValue);
                    break;
                }

                case InputValidations.AGE: {
                    validResult = self.validateService.isAgeValid(self.fieldValue);
                    break;
                }

                case InputValidations.FULL_NAME: {
                    validResult = self.validateService.isFullNameValid(self.fieldValue);
                    break;
                }

                default: {
                    validResult = true;
                }
            }

            return validResult && self.isValueLengthValid;
        },

        get textError(): string {
            if (self.isExceedingMaxLength) {
                return self.t(`Допускается от ${self.minLength} до ${self.length} символов`,
                    `Allowed characters from ${self.minLength} to ${self.length}`);
            }
            if (self.valueLength !== 0 && self.minLength && self.valueLength < self.minLength) {
                return self.t('Введите больше символов', 'Type more characters');
            }
            return self.t('Поле не валидно', 'The field is not valid.');
        },

        getAdditionalPhoneLabel(index: number): string {
            return self.t(
                'Дополнительный телефон {{num}}',
                'Additional phone {{num}}',
                {
                    num: index + 1,
                },
            );
        },
    }))
    .actions((self) => ({
        setValue(value: string | null): void {

            if (value && self.length && value?.length > self.length) {
                this.showErrorExceedingMaxLength();
                return;
            }

            const v = value || '';

            if (self.name === AttrsKey.CUSTOMER_PHONE && self.customerPhone) {
                self.customerPhone.setValue(v);

                return;
            }

            if (self.name === AttrsKey.CUSTOMER_MOBILE || self.name === AttrsKey.ADDITIONAL_PHONE) {

                const isNum = /^\d+$/.test(v);
                if (!isNum && v !== '') return;

            }

            self._value = v;
        },
        onSelect(option: { label: string; value: string }, clearKey?: boolean): void {
            self._value = option.value;
            self._option = option;
            if (clearKey) {
                self._key = '';
            }
        },
        setKey(key: string): void {
            self._key = key;
        },
        setIsExceedingMaxLength(): void {
            self.isExceedingMaxLength = !self.isExceedingMaxLength;
        },
        showErrorExceedingMaxLength(): void {
            this.setIsExceedingMaxLength();
            const timer = setTimeout(() => {
                this.setIsExceedingMaxLength();
                clearInterval(timer);
            }, 1000);
        },
        getIsMinLengthValid(status: number | null): boolean {

            if (self.minLength && status === OrderStatusesEnum.APPROVE) {

                if (!self.required && self.valueLength === 0) {
                    return true;
                }

                if (self.valueLength < self.minLength) {
                    return false;
                }

            }

            return true;
        },
    }))
    .actions((self) => ({
        setKladrResult(value: IKladrResultModel): void {
            self.kladrResult = value;
            self.setValue(value.name);
        },
    }));


export interface IFormAttributesModel extends Instance<typeof FormAttributes> {}
export interface IFormAttributesModelSnapshotIn extends SnapshotIn<typeof FormAttributes> {}
export interface IFormAttributesModelSnapshotOut extends SnapshotOut<typeof FormAttributes> {}
